import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ChiaraBannersBuilderComponent } from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "chiara-theme"
    }}>{`Chiara Theme`}</h1>
    <ChiaraBannersBuilderComponent mdxType="ChiaraBannersBuilderComponent" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      